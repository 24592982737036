import * as React from "react"
import Layout from "../components/layout"
import ButtonRdv from "../components/buttonRdv"
import digidombesWhite from "../images/digidombes-white.png"
import promo from "../images/promo.svg"

// markup
const CreationPage = () => {
  return (
    <Layout>
    <header class="my-5">
        <div class="row titre" data-aos="fade-down">
        <h1 class="item">Site internet et logo</h1>
        <div class="item">par</div>
        <img class="item mt-3 mt-sm-0" src={digidombesWhite}/>
        </div>
    </header>
    <section class="row no-gutters">
        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center col-offre">
            <div class="head">Pack <strong>image</strong> </div>
            <p class="price">250€</p>
            <p class="liste">Logo<br/>+<br/>Couleurs<br/>+<br/>Typographie</p>
            <ButtonRdv/>
        </div>

        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center col-offre col-milieu">
            <img class="promo" src={promo}/>
            <div class="head">Pack <strong>web + image</strong> </div>
            <p class="price">765€</p>
            <p class="price-y">sans abonnement</p>
            <p class="liste">Logo<br/>+<br/>Couleurs<br/>+<br/>Typographie<br/>+<br/>Site internet<br/>+<br/>Hébergement</p>
            <ButtonRdv/>
        </div>

        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center col-offre">
            <div class="head">Pack <strong>web</strong> </div>
            <p class="price">600€</p>
            <p class="price-y">sans abonnement</p>
            <p class="liste">Site internet<br/>+<br/>Hébergement</p>
            <ButtonRdv/>
        </div>
    </section>
    </Layout>
  )
}

export default CreationPage
